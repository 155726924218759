import axios from 'axios'
import { UserLocation } from '@/util/UserLocalizations'

const apiUrl = '/api/user-locations'

export const saveUserLocation = (userLocation: UserLocation) => {
   return axios.post<void>(apiUrl, userLocation)
}

export const getLastUserLocation = () => {
   return axios.get<UserLocation | null>(`${apiUrl}/last`)
}
