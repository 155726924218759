import axios from 'axios'
import { INotification } from '@/model/notification/notification.model'
import { INotificationShowed } from '@/model/notification/notification-showed.model'

const apiUrl = '/api/notifications'

export const getNotifications = ({ page, onlyUnread }) => {
   const requestUrl = `${apiUrl}?page=${page}&onlyUnread=${onlyUnread}`
   return axios.get<INotification[]>(requestUrl)
}

export const showedAllNotifications = () => {
   const requestUrl = `/api/showed-all-notifications`
   return axios.get<INotificationShowed>(requestUrl)
}

export const readNotifications = (notifications: any[]) => {
   return axios.post<any>(apiUrl + '/read', notifications)
}

export const unreadNotifications = (notifications: any[]) => {
   return axios.post<any>(apiUrl + '/unread', notifications)
}

export const deleteNotifications = (notifications: any[]) => {
   return axios.post<any>(apiUrl + '/delete', notifications)
}

export const notifyAboutError = (error: string) => {
   return axios.post<void>('/api/client-error-handler', error, {
      headers: { 'Content-Type': 'text/plain' },
   })
}

export const registerFCMToken = (token: string) => {
   return axios.post<void>('/api/fcm/register', { token })
}

export const testFCMNotification = (delayInSeconds: number) => {
   return axios.post<void>('/api/fcm/test-notification', null, {
      params: {
         delayInSeconds,
      },
   })
}
