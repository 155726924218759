import React from 'react'
import ReactDOM from 'react-dom/client'
import getStore from '@/config/store'
import { Provider } from 'react-redux'
import { bindActionCreators } from 'redux'
import { clearAuthentication } from '@/service/authentication'
import setupAxiosInterceptors from '@/config/axios-interceptor'
import './Main.css'
import { isDesktop } from 'react-device-detect'
import { HelmetProvider } from 'react-helmet-async'
import { initializeLanguage } from '@/i18n/LocaleSlice'

const store = getStore()
initializeLanguage(store)

const actions = bindActionCreators({ clearAuthentication }, store.dispatch)
setupAxiosInterceptors(() => actions.clearAuthentication())

const rootElement = document.getElementById('root') as HTMLElement
const root = ReactDOM.createRoot(rootElement)

const App = React.lazy(() => (isDesktop ? import('./AppDesktop') : import('./AppMobile')))

root.render(
   <Provider store={store}>
      <HelmetProvider>
         <App />
      </HelmetProvider>
   </Provider>,
)
