import { createSlice } from '@reduxjs/toolkit'

function initTheme() {
   // if (localStorage.theme === 'dark') return true
   // else if (localStorage.theme === 'light') return false
   //
   // return window.matchMedia('(prefers-color-scheme: dark)').matches
}

const INITIAL_STATE = {
   // darkTheme: initTheme(),
   darkTheme: false,
}

export const ThemeModeSlice = createSlice({
   name: 'themeMode',
   initialState: INITIAL_STATE,
   reducers: {
      updateTheme(state) {
         if (state.darkTheme) localStorage.setItem('theme', 'light')
         else localStorage.setItem('theme', 'dark')
         state.darkTheme = !state.darkTheme
      },
   },
})

export const { updateTheme } = ThemeModeSlice.actions

export default ThemeModeSlice.reducer
