export enum LocalStorageKey {
   ADULT_CONFIRMED = 'ADULT_CONFIRMED',
   CATEGORIZED_MODE = 'CATEGORIZED_MODE',
   FEED_VIEW_TYPE = 'FEED_VIEW_TYPE',
   COMMUNITY_SORT = 'COMMUNITY_SORT',
   LAST_USER_LOCALIZATION = 'LAST_USER_LOCALIZATION',
}

export function setLocalStorageItem(key: LocalStorageKey, value: any) {
   localStorage.setItem(key, JSON.stringify(value))
}

export function getLocalStorageItem(key: LocalStorageKey, defaultValue: any) {
   const serializedState = localStorage.getItem(key)
   if (serializedState === null || serializedState == 'undefined') return defaultValue
   return JSON.parse(serializedState)
}
