import { configureStore, ThunkAction } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import refresherReducer from '../service/refresher-reducer'
import themeModeReducer from '../service/theme-mode-reducer'
import authentication from '../service/authentication'
import notificationReducer from '@/service/notification-reducer'
import search from '@/service/search'
import localeSlice from '@/i18n/LocaleSlice'

const store = configureStore({
   reducer: {
      authentication,
      search,
      refresherReducer,
      notificationReducer,
      themeModeReducer,
      localeSlice,
   },
})

export type IRootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, IRootState, unknown, any>

export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()
const getStore = () => store
export default getStore
