import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getLocalStorageItem, LocalStorageKey, setLocalStorageItem } from '@/util/local-storage-utils'
import { UserLocation } from '@/util/UserLocalizations'
import { saveUserLocation } from '@/service/user-locations.service'

export const DietaryPreference = {
   VEGETARIAN: 'VEGETARIAN',
   VEGAN: 'VEGAN',
   GLUTEN_FREE: 'GLUTEN_FREE',
}

export const SortByChef = {
   NEWEST: 'NEWEST',
   TOP_RATED: 'TOP_RATED',
   POPULAR: 'POPULAR',
   DISTANCE: 'DISTANCE',
}

export enum CommunitySort {
   NAME = 'NAME',
   JOINED = 'JOINED',
   POPULAR = 'POPULAR',
   NEW = 'NEW',
}

export enum COMMENT_SORT_BY {
   OLD = 'OLD',
   BEST = 'BEST',
   NEW = 'NEW',
   UNREAD = 'UNREAD',
}

export enum FeedViewType {
   CARDS = 'cards',
   COMPACT = 'compact',
}

function getFeedViewTypeFromString(value: string): FeedViewType {
   const enumValues = Object.values(FeedViewType)
   if (enumValues.includes(value as FeedViewType)) {
      return value as FeedViewType
   }
   return FeedViewType.CARDS
}

const initialState: {
   mobileSearchOpen: boolean
   viewType: FeedViewType
   currentCommunity: any
   currentUserLocation: UserLocation | null
} = {
   mobileSearchOpen: false,
   viewType: getFeedViewTypeFromString(getLocalStorageItem(LocalStorageKey.FEED_VIEW_TYPE, FeedViewType.CARDS)),
   currentCommunity: null,
   currentUserLocation: null,
}

interface UpdateUserLocationPayload {
   location: UserLocation
   shouldSaveLocation: boolean
}

export const SearchSlice = createSlice({
   name: 'search',
   initialState,
   reducers: {
      updateMobileSearchBar(state) {
         state.mobileSearchOpen = !state.mobileSearchOpen
      },
      updateViewType(state, action) {
         setLocalStorageItem(LocalStorageKey.FEED_VIEW_TYPE, action.payload)
         state.viewType = action.payload
      },
      updateCurrentCommunity(state, action) {
         state.currentCommunity = action.payload
      },
      updateUserLocation(state, action: PayloadAction<UpdateUserLocationPayload>) {
         const { location, shouldSaveLocation } = action.payload
         state.currentUserLocation = location
         setLocalStorageItem(LocalStorageKey.LAST_USER_LOCALIZATION, location)
         if (shouldSaveLocation) saveUserLocation(location)
      },
   },
})

export const { updateMobileSearchBar, updateViewType, updateCurrentCommunity, updateUserLocation } = SearchSlice.actions

// Reducer
export default SearchSlice.reducer
