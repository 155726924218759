import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
   postRefresh: 0,
   commentRefresh: 0,
   communitiesRefresh: 0,
   bottomNavigationRefresh: 0,
   menuDropdownRefresh: 0,
   navigateToNewLocation: 0,
}

export const enum REFRESH_TARGET {
   POST_REFRESH,
   COMMENT_REFRESH,
   BOTTOM_NAVIGATION_REFRESH,
   MENU_DROPDOWN_REFRESH,
   COMMUNITIES_REFRESH,
   NEW_LOCATION_REFRESH,
}

export const RefresherSlice = createSlice({
   name: 'refresher',
   initialState: INITIAL_STATE,
   reducers: {
      refreshPage(state, action) {
         if (action.payload === REFRESH_TARGET.COMMENT_REFRESH) state.commentRefresh += 1
         else if (action.payload === REFRESH_TARGET.POST_REFRESH) state.postRefresh += 1
         else if (action.payload === REFRESH_TARGET.BOTTOM_NAVIGATION_REFRESH) state.bottomNavigationRefresh += 1
         else if (action.payload === REFRESH_TARGET.MENU_DROPDOWN_REFRESH) state.menuDropdownRefresh += 1
         else if (action.payload === REFRESH_TARGET.COMMUNITIES_REFRESH) state.communitiesRefresh += 1
         else if (action.payload === REFRESH_TARGET.NEW_LOCATION_REFRESH) state.navigateToNewLocation += 1
      },
   },
})

export const { refreshPage } = RefresherSlice.actions

export default RefresherSlice.reducer
