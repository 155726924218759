import axios from 'axios'
import { SERVER_API_URL } from '@/env'
import { toast } from 'react-toastify'
import i18n from 'i18next'

const TIMEOUT = 110 * 1000
axios.defaults.timeout = TIMEOUT
axios.defaults.baseURL = SERVER_API_URL

// console.log('setupAxiosInterceptors ', VITE_SERVER_API_URL, " axios baseURL", axios.defaults.baseURL, 'base path ', SITE_BASE_URL)

const setupAxiosInterceptors = onUnauthenticated => {
   const onRequestSuccess = config => {
      const token = localStorage.getItem('authenticationToken') || sessionStorage.getItem('authenticationToken')
      if (token) {
         config.headers.Authorization = `Bearer ${token}`
      }
      return config
   }
   const onResponseSuccess = (response: any) => response

   const onResponseError = (error: { response: { status: number; data: { errorKey: string | any[] } } }) => {
      if (error.response) {
         if (error.response.status === 401) {
            if (error.response?.data?.errorKey?.length > 0) return Promise.reject(error)

            onUnauthenticated()
            return Promise.reject({})
         }
         if (error.response.status === 403) return Promise.reject(error)

         const errorCode = error.response?.data?.errorKey
         const params = error.response?.data
         if (params) {
            for (const [key, value] of Object.entries(params)) {
               if (key.startsWith('translate_') || key.startsWith('num_'))
                  params[key] = i18n.t('params.' + value.toString().toLowerCase())
            }
         }
         const errorMessage = i18n.t('error.' + errorCode, params)
         // @ts-ignore
         const autoCloseDuration = Math.min(Math.max(errorMessage.length * 40, 2000), 8000)

         if (errorCode == undefined) {
            toast.error(i18n.t('error.unknown_error_contact_us'))
            return Promise.reject({})
         }

         // @ts-ignore
         toast.error(errorMessage, { autoClose: autoCloseDuration })
      } else {
         toast.error(i18n.t('error.unknown_error_contact_us'))
      }
      return Promise.reject(error)
   }

   axios.interceptors.request.use(onRequestSuccess)
   axios.interceptors.response.use(onResponseSuccess, onResponseError)
}

export default setupAxiosInterceptors
