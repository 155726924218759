import axios from 'axios'
import { IUser } from '@/model/user.model'

const apiUrl = '/api/'

export const getAccount = async () => {
   return axios.get<IUser>(apiUrl + 'account')
}
export const updateLocale = async (locale: string) => {
   return axios.post<any>(apiUrl + 'account/locale', locale.toUpperCase())
}
export const changePassword = async dto => {
   return axios.post<any>(apiUrl + 'account/change-password', dto)
}
export const changeEmail = async mail => {
   return axios.post<any>(apiUrl + 'account/change-email', { mail: mail })
}
export const confirmAge = async confirmAge => {
   return axios.post<any>(apiUrl + 'account/confirm-age', confirmAge)
}
export const changeAboutMe = async aboutMe => {
   return axios.post<any>(apiUrl + 'account/about-me', aboutMe)
}
export const changeContentSettings = async settings => {
   return axios.post<any>(apiUrl + 'account/change-content-config', settings)
}
export const confirmEmail = async key => {
   return axios.get<ActivationStatus>(apiUrl + `activate?key=${key}`, key)
}
export const scheduleToDeleteTheAccount = async entity => {
   return axios.post<any>('/api/account/schedule-delete', entity)
}

interface ActivationStatus {
   status: string
   message: string
}
